import { ArchiveIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { LOCALES } from "lib/constants"
import { SanityValues } from "sanity.config"
import { pageSchema } from "schemas/documents/page"

const type = defineType({
  type: "document",
  name: "form",
  title: "Form",
  icon: ArchiveIcon,

  fields: [
    defineField({
      name: "locale",
      type: "string",
      options: {
        list: LOCALES.map(locale => ({
          title: locale,
          value: locale,
        })) as [{ title: string; value: string }],
      },
    }),
    defineField({
      name: "formId",
      title: "Form ID",
      type: "string",
    }),
    defineField({
      name: "embedId",
      title: "Embed ID",
      type: "string",
      hidden: ({ parent }) => parent?.formType !== "typeformEmbed",
    }),
    defineField({
      name: "name",
      title: "Name",
      type: "string",
    }),
    defineField({
      name: "fields",
      title: "Fields",
      type: "array",
      hidden: ({ parent }) => parent?.formType === "typeformEmbed",
      of: [
        defineArrayMember({
          type: "object",
          name: "textarea",
          title: "Text Area",

          fields: [
            defineField({
              name: "hubspotId",
              title: "Hubspot ID",
              type: "string",
            }),
            defineField({
              name: "label",
              title: "Label",
              type: "string",
            }),
          ],
        }),
        defineArrayMember({
          type: "object",
          name: "select",
          title: "Select",

          fields: [
            defineField({
              name: "hubspotId",
              title: "Hubspot ID",
              type: "string",
            }),
            defineField({
              name: "label",
              title: "Label",
              type: "string",
            }),
            defineField({
              name: "options",
              title: "Options",
              type: "array",
              of: [
                defineArrayMember({
                  type: "object",

                  fields: [
                    defineField({
                      name: "label",
                      title: "Label",
                      type: "string",
                    }),
                    defineField({
                      name: "value",
                      title: "Value",
                      type: "string",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    defineField({
      name: "tags",
      title: "Tags",
      type: "array",
      hidden: ({ parent }) => parent?.formType === "typeformEmbed",
      of: [defineArrayMember({ type: "string" })],
    }),
    defineField({
      name: "submitLabel",
      title: "Submit Label",
      type: "string",
      hidden: ({ parent }) => parent?.formType === "typeformEmbed",
    }),
    defineField({
      name: "formType",
      type: "string",
      options: {
        list: [
          {
            title: "Generic",
            value: "generic",
          },
          {
            title: "Analyze Voice",
            value: "analyzeVoice",
          },
          {
            title: "Create account",
            value: "createAccount",
          },
          {
            title: "Create audtis account",
            value: "createAuditsAccount",
          },
          {
            title: "Typeform Embed",
            value: "typeformEmbed",
          },
        ],
      },
    }),
    defineField({
      name: "thankYouPage",
      title: "Thank You Page",
      type: "reference",
      hidden: ({ parent }) => parent?.formType === "typeformEmbed",
      to: [{ type: pageSchema.name }],
      options: {
        filter: ({ document }) => {
          return {
            filter: "locale == $locale",
            params: {
              locale: document["locale"],
            },
          }
        },
      },
    }),
  ],

  preview: {
    select: {
      locale: "locale",
      name: "name",
    },
    prepare: value => ({
      title: `Form ${value.name} (${value.locale})`,
    }),
  },
})

export const formType = type
export type Form = SanityValues["form"]
export const formSchema = type
